import React, { Component } from "react";
import Cover from "../Cover/Cover";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import config from "../../../data/SiteConfig";
import "./About.css";

export default class About extends Component {
  render() {
    const { cover } = this.props;
    return (
      <div>
        <Cover cover={cover} fadein fixed />
        <Header />
        <div className="about">
          <h1>GUMLAB</h1>
          <h3>
            FROM OFFICES IN MELBOURNE AND SHANGHAI, GUMLAB WORKS WITH A BROAD
            RANGE OF CLIENTS IN THE ADVERTISING, BROADCAST, FILM AND
            ENTERTAINMENT INDUSTRIES.
          </h3>
          <h3>
            WE TAKE OUR WORK SERIOUSLY, BUT NOT OURSELVES. THIS ATTITUDE IS
            REFLECTED IN OUR WORKPLACE, WHERE WE STRIVE DAILY TO CREATE AN
            ENVIRONMENT THAT BREEDS A CULTURE OF EXCELLENCE, WHILE REMAINING
            FUN, COLLABORATIVE, AND EGO-FREE.
          </h3>

          <div className="divider" />

          <h1>WE ARE HIRING</h1>
          <div className="about-opening">
            <h2>AE动画师</h2>
            <p>
              GUMLAB需要一名有才华的After Effects动画师加入我们在上海的团队。
            </p>
            <p>
              如果你是一位富有创造力和热忱的动画师，精通After
              Effects，AI，PS，PR，(Cinema
              4D经验者优先)，玩转动画曲线，并对于设计和审美有自己的独特的认知，
              我们需要你的加入，请将作品和简历发至
              <a href="mailto:info@gum-lab.com">info@gum-lab.com</a>
            </p>
          </div>

          <div className="about-opening">
            <h2>三维动画师</h2>
            <p>GUMLAB需要一名C4D方向的三维动画师加入我们在上海的团队。</p>
            <p>
              如果你注重composition，colour和design，拥有motion
              graphic动画和Octane渲染器的功底，高效，注重细节，有条理，并能够在紧迫的工期内工作，
              熟练运用在C4D，Mograph，Octane，Photoshop，Illustrator和After
              Effects，我们需要你的加入，请将作品和简历发至
              <a href="mailto:info@gum-lab.com">info@gum-lab.com</a>
            </p>
          </div>

          <div className="about-opening">
            <h2>三维设计师</h2>
            <p>
              GUMLAB需要一名具有高级灯光材质技能的三维设计师加入我们上海的团队。
            </p>
            <p>
              如果你注重细节和设计，拥有深厚的美术功底，富有三维材质灯光的工作经验，高效，注重细节，有条理，并能够在紧迫的工期内工作，熟练运用在C4D，Octane，
              Redshift渲染器，Photoshop和Illustrator，我们需要你的加入，请将作品和简历发至
              <a href="mailto:info@gum-lab.com">info@gum-lab.com</a>
            </p>
          </div>

          <div className="about-opening">
            <h2>Art 美术</h2>
            <p>
              GUMLAB正在寻找一位才华横溢，充满激情的设计师加入我们的上海团队。
              {" "}
            </p>
            <p>
              设计师/插画师将与我们的创意团队合作，共同开发项目的视觉创意。
              有能力运用各种设计风格和美学，英语流利，对潮流有着敏锐的嗅觉，并且理解动画的流程。
              熟练运用在Photoshop，Illustrator，indesign。有动画能力者优先考虑，我们需要你的加入，请将作品和简历发至
              <a href="mailto:info@gum-lab.com">info@gum-lab.com</a>
            </p>
          </div>
        </div>
        <Footer config={config} />
        <Cover cover={cover} fixed />
      </div>
    );
  }
}
